@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --lily: 250, 250, 250; /*White*/
    --lilium: 255, 192, 173; /*Melon-Orange*/
    --daffodil: 250, 224, 140; /*Yellow */
    --daisy: 250, 242, 227; /* Light Yellow*/
    --marigold: 245, 206, 159; /* Orange*/
    --iris: 147, 191, 201; /*Blue*/
    --tulip: 176, 227, 161; /*Green*/
    --wisteria: 184, 158, 220; /*Purple*/
    --rose: 247, 161, 185; /*Pink*/
    --taupe: 153, 84, 37; /*Brown*/
    --cosmos: 31, 29, 33; /*Black*/
  }

  .dark {
    /* Enchanted Forest Dark Theme Color Palette */
    --lily: 82, 76, 66; /* Deep Charcoal for primary background */
    --lilium: 244, 114, 89; /* Warm Coral for subtle accents */
    --daffodil: 216, 119, 34; /* Bright Amber for highlights */
    --daisy: 50, 1, 47; /* Soft Olive for muted backgrounds */
    --marigold: 212, 84, 37; /* Rich Copper for warm elements */
    --iris: 67, 99, 130; /* Deep Slate Blue for cool accents */
    --tulip: 70, 123, 89; /* Moss Green for natural tones */
    --wisteria: 99, 79, 151; /* Mystic Violet for magical elements */
    --rose: 216, 92, 111; /* Enchanted Ruby for vibrant accents */
    --taupe: 241, 209, 162; /* Dark Mahogany for earthy depth */
    --cosmos: 226, 223, 208; /* Light Silver for text on dark background */
  }
  .other {
    --lily: 58, 54, 61; /*Black*/
    --lilium: 255, 192, 173; /*Melon-Orange*/
    --daffodil: 227, 204, 129; /*Yellow*/
    --daisy: 214, 207, 195; /* Light Yellow*/
    --marigold: 212, 177, 138; /* Light Orange*/
    --iris: 154, 219, 234; /*Blue*/
    --tulip: 176, 227, 161; /*Green*/
    --wisteria: 184, 158, 220; /*Purple*/
    --rose: 247, 161, 217; /*Pink*/
    --taupe: 129, 100, 90; /*Brown*/
    --cosmos: 250, 250, 250; /*White*/
  }
}
@layer base {
  * {
    @apply border-cosmos;
  }
  body {
    font-synthesis-weight: none;
    text-rendering: optimizeLegibility;
  }

  .app {
    @apply bg-[url('assets/bg-image-2.jpeg')] bg-cover bg-no-repeat bg-center text-cosmos font-sans h-screen overflow-hidden relative bg-blend-multiply bg-lily;
  }

  .app-header {
    @apply w-screen pt-10 pc:px-24 tablet:px-10 phone:px-4 h-[88px];
    text-wrap: nowrap;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 999;
  }

  .section {
    min-height: 60vh;
    width: 100%;
    display: flex;
    padding: 16px;
  }
}

@layer components {
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
    margin-right: -24px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fafafa;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  /* Track Dark Theme */
  .dark ::-webkit-scrollbar-track {
    background: #524c42;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-marigold/60;
    border: 3px solid #fafafa;
    border-radius: 10px;
    width: 6px;
  }

  /* Handle Dark Theme */
  .dark ::-webkit-scrollbar-thumb {
    border: 3px solid #524c42;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-marigold;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .striped-div {
    background: repeating-linear-gradient(
      to right,
      #e5b803 0px,
      #e5b803 40px,
      #000000 40px,
      #000000 60px
    );
  }

  .small-striped-div {
    background: repeating-linear-gradient(
      to right,
      #e5b803 0px,
      #e5b803 10px,
      #000000 10px,
      #000000 20px
    );
  }
}
