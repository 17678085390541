.colored-multiselect {
  .ms-input-container {
    border-color: rgb(143, 199, 142);
  }
  .ms-selectionbox,
  .ms-listitem:hover {
    background-color: rgba(154, 239, 153, 0.4);
  }
  .ms-selectionbox-button {
    background-color: rgb(44, 104, 43);
  }
  .ms-spinning-loader {
    color: rgb(143, 199, 142);
  }
  .ms-listitem-checkbox {
    accent-color: rgb(143, 199, 142);
  }
  .ms-linear-progressbar {
    background-color: rgba(154, 239, 153, 0.4);
  }
}
